import React from 'react';

import { getServerSidePropsBase } from '@actions/getServerSidePropsBase';
import { wrapper } from '../store/withRedux';
import Params from './[...params]';

const HomePage = ({ pageData }) => <Params />;


// export const getServerSideProps = withCompressedServerSide(getServerSidePropsBase);
export const getServerSideProps = wrapper.getServerSideProps((store )=> getServerSidePropsBase(store));

export default HomePage;
